import React, {useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus} from '@fortawesome/free-solid-svg-icons';
import {useTranslation} from "react-i18next";
import {fetchProducts, searchProducts} from "../../services/productsService";
import heart_icon from '../../assets/icons/heart_icon.svg';

const HeaderSection = () => {
    const {t} = useTranslation();
    const [activeSubmenu, setActiveSubmenu] = useState('');
    const [showModal, setShowModal] = useState(false); // Stav pro modal
    const [formData, setFormData] = useState({
        bedrooms: '',
        bathrooms: '',
        toilets: '',
        parkingSpaces: '',
        address: '',
        accommodationType: '',
        priceFrom: '',
        priceTo: '',
        wifi: false,
        sauna: false,
        indoorPool: false,
        jacuzzi: false,
        bathtub: false,
        fireplace: false,
        fenced: false,
        petFriendly: false,
        familyFriendly: false,
        breakfast: false,
        lunch: false,
        dinner: false,
        airConditioning: false,
        heating: false,
        elevator: false,
        spa: false,
        steamRoom: false,
        crib: false,
        highChair: false,
        playCorner: false,
        lounge: false,
        salon: false,
        restaurant: false,
        bicycleStorage: false,
        washingMachine: false,
        dryer: false,
        darts: false,
        tableFootball: false,
        tableTennis: false,
        waterpark: false,
        poolArea: false,
        lake: false,
        currency: '',
        suitableForEvents: false,
        rating: '',
    });

    const handleFavourite = (id) => {
        const favourites = JSON.parse(localStorage.getItem('favourite')) || [];
        if (!favourites.includes(id)) {
            favourites.push(id);
            localStorage.setItem('favourite', JSON.stringify(favourites));
        }
    };

    const [token, setToken] = useState('your-auth-token'); // Replace with actual token retrieval logic
    const [products, setProducts] = useState([]); // Define products state
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const handleMouseEnter = (submenu) => {
        setActiveSubmenu(submenu);
    };

    const handleMouseLeave = () => {
        setActiveSubmenu('');
    };

    const openModal = () => setShowModal(true);
    const closeModal = () => setShowModal(false);

    const handleChange = (e) => {
        const {name, value, type, checked} = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true); // Start loading

        try {
            const fetchedProducts = await searchProducts(formData); // Call the custom fetch function
            setProducts(fetchedProducts.data); // Set the fetched products
            console.log(fetchedProducts); // Debugging: log fetched products
        } catch (err) {
            setError('Failed to load products.'); // Handle error gracefully
        } finally {
            setLoading(false); // Stop loading
            closeModal(); // Close modal after search
        }
    };


    return (
        <div className="header-section" style={{backgroundImage: 'none'}}>
            <h1 className="home-h1 max-w-100">{t('welcome')}</h1>
            <div className="search-bar">
                <div
                    className="dropdown"
                    onMouseEnter={() => handleMouseEnter('location-submenu')}
                    onMouseLeave={handleMouseLeave}
                >
                    <button className="button-60" role="button">{t('location')}</button>
                    <div className={`location-submenu ${activeSubmenu === 'location-submenu' ? 'active' : ''}`}>
                        <input type="text" placeholder={t('searchLocation')} className={'search-input'}/>
                        <ul>
                            <li>{t('location1')}</li>
                            <li>{t('location2')}</li>
                            <li>{t('location3')}</li>
                            <li className="show-all">{t('showAll')}</li>
                        </ul>
                    </div>
                </div>

                <div
                    className="dropdown"
                    onMouseEnter={() => handleMouseEnter('date-submenu')}
                    onMouseLeave={handleMouseLeave}
                >
                    <button className="button-60" role="button">{t('date')}</button>
                    <div className={`date-submenu ${activeSubmenu === 'date-submenu' ? 'active' : ''}`}>
                        <input type="date"/>
                    </div>
                </div>

                <div
                    className="dropdown"
                    onMouseEnter={() => handleMouseEnter('guests-submenu')}
                    onMouseLeave={handleMouseLeave}
                >
                    <button className="button-60" role="button">{t('guests')}</button>
                    <div className={`guests-submenu ${activeSubmenu === 'guests-submenu' ? 'active' : ''}`}>
                        <div className="group">
                            <label>{t('adults')}</label>
                            <div className="count">
                                <button>-</button>
                                <span>0</span>
                                <button>+</button>
                            </div>
                        </div>
                        <div className="group">
                            <label>{t('children')}</label>
                            <div className="count">
                                <button>-</button>
                                <span>0</span>
                                <button>+</button>
                            </div>
                        </div>
                        <div className="group">
                            <label className="label-group">
                                <input type="checkbox"/> {t('pets')}
                            </label>
                        </div>
                        <div className="group">
                            <label className="label-group">
                                <input type="checkbox"/> {t('wholePlace')}
                            </label>
                        </div>
                    </div>
                </div>

                <div
                    className="dropdown"
                    onMouseEnter={() => handleMouseEnter('accommodation-type-submenu')}
                    onMouseLeave={handleMouseLeave}
                >
                    <button className="button-60" role="button">{t('accommodationType')}</button>
                    <div
                        className={`accommodation-type-submenu ${activeSubmenu === 'accommodation-type-submenu' ? 'active' : ''}`}>
                        <label className="label-group"><input type="checkbox" name="property-type"
                                                              value="hotel"/> {t('hotel')}</label>
                        <label className="label-group"><input type="checkbox" name="property-type"
                                                              value="apartment"/> {t('apartment')}</label>
                        <label className="label-group"><input type="checkbox" name="property-type"
                                                              value="house"/> {t('house')}</label>
                        <label className="label-group"><input type="checkbox" name="property-type"
                                                              value="villa"/> {t('villa')}</label>
                        <label className="label-group"><input type="checkbox" name="property-type"
                                                              value="other"/> {t('other')}</label>
                    </div>
                </div>

                <button type="button" className="button-60" onClick={openModal}>
                    <FontAwesomeIcon icon={faPlus}/>
                </button>

                <button type="button" className="button-60 search-btn" onClick={handleSubmit}>
                    {t('search')}
                </button>
            </div>

            {showModal && (
                <div className="modal">
                    <div className="modal-content">
                        {/* Křížek pro zavření modálního okna */}
                        <span className="close" onClick={closeModal}>&times;</span>
                        <h2>{t('moreOptions')}</h2>

                        {/* Možnosti ubytování */}
                        <section className="modal-section">
                            <h3>Možnosti ubytování</h3>
                            <form onSubmit={handleSubmit}>
                                <input
                                    type="number"
                                    name="bedrooms"
                                    placeholder="Počet ložnic"
                                    value={formData.bedrooms}
                                    onChange={handleChange}
                                />
                                <input
                                    type="number"
                                    name="bathrooms"
                                    placeholder="Počet koupelen"
                                    value={formData.bathrooms}
                                    onChange={handleChange}
                                />
                                <input
                                    type="number"
                                    name="toilets"
                                    placeholder="Počet toalet"
                                    value={formData.toilets}
                                    onChange={handleChange}
                                />
                                <input
                                    type="number"
                                    name="parkingSpaces"
                                    placeholder="Počet parkovacích míst"
                                    value={formData.parkingSpaces}
                                    onChange={handleChange}
                                />
                                <input
                                    type="text"
                                    name="address"
                                    placeholder="Adresa"
                                    value={formData.address}
                                    onChange={handleChange}
                                />
                                <input
                                    type="text"
                                    name="accommodationType"
                                    placeholder="Typ ubytování"
                                    value={formData.accommodationType}
                                    onChange={handleChange}
                                />
                            </form>
                        </section>

                        {/* Služby ubytování */}
                        <section className="modal-section">
                            <h3>Služby ubytování</h3>
                            <label>
                                <input
                                    type="checkbox"
                                    name="wifi"
                                    checked={formData.wifi}
                                    onChange={handleChange}
                                />
                                WiFi
                            </label>
                            <label>
                                <input
                                    type="checkbox"
                                    name="sauna"
                                    checked={formData.sauna}
                                    onChange={handleChange}
                                />
                                Sauna
                            </label>
                            <label>
                                <input
                                    type="checkbox"
                                    name="breakfast"
                                    checked={formData.breakfast}
                                    onChange={handleChange}
                                />
                                Snídaně
                            </label>
                            {/* Přidejte další checkboxy pro další služby */}
                        </section>

                        {/* Sportovní možnosti */}
                        <section className="modal-section">
                            <h3>Sportovní možnosti</h3>
                            <label>
                                <input
                                    type="checkbox"
                                    name="fitness"
                                    checked={formData.fitness}
                                    onChange={handleChange}
                                />
                                Fitness
                            </label>
                            <label>
                                <input
                                    type="checkbox"
                                    name="bowling"
                                    checked={formData.bowling}
                                    onChange={handleChange}
                                />
                                Bowling
                            </label>
                            <label>
                                <input
                                    type="checkbox"
                                    name="tableTennis"
                                    checked={formData.tableTennis}
                                    onChange={handleChange}
                                />
                                Stolní tenis
                            </label>
                            {/* Přidejte další checkboxy pro další sportovní možnosti */}
                        </section>

                        {/* Tlačítka pro potvrzení a resetování */}
                        <div className="modal-buttons">
                            <button type="button" className="modal-submit-button" onClick={handleSubmit}>
                                {t('confirm')}
                            </button>
                            <button type="button" className="modal-reset-button" onClick={() => setFormData({
                                bedrooms: '',
                                bathrooms: '',
                                toilets: '',
                                parkingSpaces: '',
                                address: '',
                                accommodationType: '',
                                priceFrom: '',
                                priceTo: '',
                                wifi: false,
                                sauna: false,
                                indoorPool: false,
                                jacuzzi: false,
                                bathtub: false,
                                fireplace: false,
                                fenced: false,
                                petFriendly: false,
                                familyFriendly: false,
                                breakfast: false,
                                lunch: false,
                                dinner: false,
                                airConditioning: false,
                                heating: false,
                                elevator: false,
                                spa: false,
                                steamRoom: false,
                                crib: false,
                                highChair: false,
                                playCorner: false,
                                lounge: false,
                                salon: false,
                                restaurant: false,
                                bicycleStorage: false,
                                washingMachine: false,
                                dryer: false,
                                darts: false,
                                tableFootball: false,
                                tableTennis: false,
                                waterpark: false,
                                poolArea: false,
                                lake: false,
                                currency: '',
                                suitableForEvents: false,
                                rating: '',
                            })}>
                                {t('reset')}
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {loading && <p>Loading...</p>}
            {error && <p>{error}</p>}
            <div className="search-grid-item">
                {products.length > 0 && (
                    <div className="products-list">
                        {products.map((product) => (
                            <div className="grid-item">
                                <div className="product-card">
                                    <img src={product.mainFoto} alt={product.name} className="product-image"/>
                                    <div className="labels-box">
                                        {product.approved === 0 && <div className="search-label">Vyprodané</div>}
                                        {product.discount && <div className="search-label">Sleva</div>}
                                    </div>
                                    <button
                                        className="heart-button"
                                        onClick={() => handleFavourite(product.id)}
                                        aria-label="Add to favourites"
                                    >
                                        <img src={heart_icon} className="heart-icon" alt="Favourite"/>
                                    </button>
                                    <div className="product-info">
                                        <h3 className="product-title">{product.name}</h3>
                                        <p className="product-description">????</p>
                                        <p className="product-description">{product.rating ?? 0}*</p>
                                        <p className="product-price">{product.bedrooms} Lůžek</p>
                                        <p className="product-price">{product.priceFrom} {t('currency')} - {product.priceTo} {t('currency')} /
                                            noc</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default HeaderSection;
