import React, { useState } from 'react';
import housemain from '../../assets/images/housemain.png';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function Home() {
    const { t } = useTranslation();
    const [activeSubmenu, setActiveSubmenu] = useState('');

    const handleMouseEnter = (submenu) => {
        setActiveSubmenu(submenu);
    };

    const handleMouseLeave = () => {
        setActiveSubmenu('');
    };

    const handleClick = () => {
        // Logika pro předání údajů do vyhledávání
    };

    return (
            <div className="header-section">
                <h1 className="home-h1 max-w-100">{t('welcome')}</h1>
                <div className="search-bar">
                    <div
                        className="dropdown"
                        onMouseEnter={() => handleMouseEnter('location-submenu')}
                        onMouseLeave={handleMouseLeave}
                    >
                        <button className="button-60" role="button">{t('location')}</button>
                        <div className={`location-submenu ${activeSubmenu === 'location-submenu' ? 'active' : ''}`}>
                            <input type="text" placeholder={t('searchLocation')} className={'search-input'} />
                            <ul>
                                {/* Seznam lokalit */}
                                <li>{t('location1')}</li>
                                <li>{t('location2')}</li>
                                <li>{t('location3')}</li>
                                {/* ... */}
                                <li className="show-all">{t('showAll')}</li>
                            </ul>
                        </div>
                    </div>

                    <div
                        className="dropdown"
                        onMouseEnter={() => handleMouseEnter('date-submenu')}
                        onMouseLeave={handleMouseLeave}
                    >
                        <button className="button-60" role="button">{t('date')}</button>
                        <div className={`date-submenu ${activeSubmenu === 'date-submenu' ? 'active' : ''}`}>
                            <input type="date" />
                        </div>
                    </div>

                    <div
                        className="dropdown"
                        onMouseEnter={() => handleMouseEnter('guests-submenu')}
                        onMouseLeave={handleMouseLeave}
                    >
                        <button className="button-60" role="button">{t('guests')}</button>
                        <div className={`guests-submenu ${activeSubmenu === 'guests-submenu' ? 'active' : ''}`}>
                            <div className="group">
                                <label>{t('adults')}</label>
                                <div className="count">
                                    <button>-</button>
                                    <span>0</span>
                                    <button>+</button>
                                </div>
                            </div>
                            <div className="group">
                                <label>{t('children')}</label>
                                <div className="count">
                                    <button>-</button>
                                    <span>0</span>
                                    <button>+</button>
                                </div>
                            </div>
                            <div className="group">
                                <label className="label-group">
                                    <input type="checkbox"/> {t('pets')}
                                </label>
                            </div>
                            <div className="group">
                                <label className="label-group">
                                    <input type="checkbox"/> {t('wholePlace')}
                                </label>
                            </div>
                        </div>
                    </div>

                    <div
                        className="dropdown"
                        onMouseEnter={() => handleMouseEnter('accommodation-type-submenu')}
                        onMouseLeave={handleMouseLeave}
                    >
                        <button className="button-60" role="button">{t('accommodationType')}</button>
                        <div
                            className={`accommodation-type-submenu ${activeSubmenu === 'accommodation-type-submenu' ? 'active' : ''}`}>
                            <label className="label-group"><input type="checkbox" name="property-type" value="hotel"/> {t('hotel')}</label>
                            <label className="label-group"><input type="checkbox" name="property-type" value="apartment"/> {t('apartment')}</label>
                            <label className="label-group"><input type="checkbox" name="property-type" value="house"/> {t('house')}</label>
                            <label className="label-group"><input type="checkbox" name="property-type" value="villa"/> {t('villa')}</label>
                            <label className="label-group"><input type="checkbox" name="property-type" value="other"/> {t('other')}</label>
                        </div>
                    </div>

                    <Link
                        to="/search"
                        onClick={handleClick}
                        className="button-60 search-btn"
                        role="button"
                    >
                        {t('search')}
                    </Link>
                </div>
            </div>
    );
}

export default Home;
