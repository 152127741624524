import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEdit, faTrash} from '@fortawesome/free-solid-svg-icons';
import {useTranslation} from "react-i18next";

const VariantsList = ({products, productid, onEdit, onDelete,}) => {
    const {t} = useTranslation();
    return (
        <ul className="product-list">
            {products.map(item => (
                <li key={item.id} className="product-item">
                    <div className="product-image-container"
                         style={{backgroundImage: `url(https://chatynapronajem.cz/backend/www/Soubory/Products/${productid}/Variants/${item.mainFoto})`}}>
                        <div className="product-name">
                            <span>{item.name}</span>
                        </div>
                        <div className="show-info">
                            <span>142 {t('buys')}</span>
                        </div>
                        <div className="actions">
                            <button className={'action-button'} onClick={() => onEdit(item.id)}>
                                <FontAwesomeIcon icon={faEdit}/>
                            </button>
                            <button className={'action-button'} onClick={() => onDelete(item.id)}>
                                <FontAwesomeIcon icon={faTrash}/>
                            </button>
                        </div>
                    </div>
                </li>
            ))}
        </ul>
    );
};

export default VariantsList;
