import React, {useCallback, useEffect, useState} from 'react';
import BasicInfo from '../components/Products/BasicInfo';
import config from "../Config";
import {useDropzone} from 'react-dropzone';
import authService from "../services/authService";
import {useNavigate, useParams} from "react-router-dom"; // Přidáno useParams
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRotateBackward} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {fetchPhotos, fetchProduct, fetchVariant, fetchVariantPhotos} from "../services/productsService";
import {CheckboxGroup, eats, others, services, sports, waters} from "../components/Products/productCheckboxs"
import BasicInfoVariant from "../components/Products/BasicInfoVariant";

const AccommodationForm = () => {
    const navigate = useNavigate();
    const {productid,id} = useParams(); // Načtení ID z URL
    const isEdit = !!id; // Určení, zda se jedná o editaci
    const [preloadedPhotos, setPreloadedPhotos] = useState([]);
    const token = authService.getToken();

    useEffect(() => {
        if (!authService.isAuthenticated()) {
            navigate('/login');
        }
    }, [navigate]);

    useEffect(() => {
        if (isEdit) {
            const loadPreloadedPhotos = async () => {
                try {
                    const photoUrls = await fetchVariantPhotos(token, id);
                    setPreloadedPhotos(photoUrls.urls);
                    console.log(preloadedPhotos);
                } catch (error) {
                    console.error('Chyba při načítání fotek:', error);
                }
            };

            loadPreloadedPhotos();
        }
    }, [isEdit, token, id]); // Přidáme závislosti do useEffect

    const removePreloadedPhoto = (photoName) => {
        setPreloadedPhotos((prevPhotos) =>
            prevPhotos.filter((photo) => photo.name !== photoName)
        );
    };

    const [formState, setFormState] = useState({
        description: '',
        bedrooms: 0,
        priceFrom: 0,
        priceTo: 0,
        mainFoto: null,
        othersPhotos: [],
        name: '',
        bathrooms: 0,
        toilets: 0,
        parkingSpaces: 0,
        address: '',
        accommodationType: '',
        wifi: false,
        sauna: false,
        indoorPool: false,
        jacuzzi: false,
        bathtub: false,
        fireplace: false,
        fenced: false,
        petFriendly: false,
        familyFriendly: false,
        rating: '',
        meals: '',
        amenities: '',
        wellness: '',
        crib: false,
        highChair: false,
        playCorner: false,
        lounge: false,
        salon: false,
        bazen: false,
        televizor: false,
        restaurant: false,
        bicycleStorage: false,
        washingMachine: false,
        dryer: false,
        darts: false,
        tableFootball: false,
        tableTennis: false,
        waterpark: false,
        poolArea: false,
        tenis: false,
        fotbal: false,
        fitness: false,
        product_id: null,
        bowling: false,
        currency: 'eur',
        lake: false,
        suitableForEvents: false,
        photoBundle: {
            id: 'unique-bundle-id',
            name: 'bundle-name',
            photos: []
        },
        obed: false,
        ranajky: false,
        vecera: false,
        polpenzia: false,
        plnaPenzia: false,
        allForUs: false,
        kitchen: false,
        stove: false,
        oven: false,
        kettle: false,
        fridge: false,
        freezer: false,
        microwave: false,
        dishwasher: false,
        allInclusive: false,
        parking: false,
        nearSkiSlope: false,
        towels: false,
        grill: false,
        cauldron: false,
        indoorFireplace: false,
        outdoorFireplace: false,
        satellite: false,
        streamingService: false,
        playground: false,
        slide: false,
        trampoline: false,
        swing: false,
        climbingFrame: false,
        socialGames: false,
        volleyball: false,
        billiard: false,
        bikeRental: false
    });

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    // Fetch product data if in edit mode
    useEffect(() => {
        if (isEdit) {
            const fetchProductData = async () => {
                try {

                    const response = await fetchVariant(token, id);

                    console.log('Fetch Product Response:', response); // Debugging line

                    // If the response is not a Response object, you might need to adjust the handling here
                    const responseData = typeof response.json === 'function' ? await response.json() : response;

                    // Check if the response status is 'success'
                    if (responseData.status !== 'success') {
                        throw new Error('Failed to fetch product data');
                    }

                    const productData = responseData.data;

                    setFormState({
                        ...formState,
                        ...productData
                    });

                    if (productData.mainFoto) {
                        const previewUrl = `https://chatynapronajem.cz/backend/www/Soubory/Products/${productData.product_id}/Variants/${productData.mainFoto}`;

                        const fileEvent = {
                            target: {
                                id: 'mainFoto',
                                value: productData.mainFoto,
                            },
                        };
                        handleChange(fileEvent);
                    }

                } catch (err) {
                    setError(`Error fetching product data: ${err.message}`);
                }
            };

            fetchProductData();
        }
    }, [id, isEdit]); // Znovu spuštění efektu při změně id nebo isEdit


    const onDrop = useCallback((acceptedFiles) => {
        setFormState(prevState => ({
            ...prevState,
            photoBundle: {
                ...prevState.photoBundle,
                photos: [
                    ...prevState.photoBundle.photos,
                    ...acceptedFiles
                ]
            }
        }));
    }, []);

    const {getRootProps, getInputProps} = useDropzone({
        onDrop: (acceptedFiles) => {
            setFormState(prevState => ({
                ...prevState,
                photoBundle: {
                    ...prevState.photoBundle,
                    photos: [
                        ...prevState.photoBundle.photos,
                        ...acceptedFiles
                    ]
                }
            }));
        }
    });

    const removePhoto = (index) => {
        setFormState(prevState => ({
            ...prevState,
            othersPhotos: prevState.othersPhotos.filter((_, i) => i !== index)
        }));
    };

    const handleChange = (e) => {
        const {id, value, type, checked, files} = e.target;

        if (type === 'checkbox') {
            setFormState((prevState) => ({
                ...prevState,
                [id]: checked,
            }));
        } else if (type === 'file') {
            setFormState((prevState) => ({
                ...prevState,
                [id]: files[0], // Handle file input
            }));
        } else {
            setFormState((prevState) => ({
                ...prevState,
                [id]: value,
            }));
        }
    };

    const {t} = useTranslation();
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        setError(null);
        setSuccess(null);

        try {
            const token = authService.getToken();

            // Convert formState to FormData to handle file uploads
            const formData = new FormData();

            // Append all non-file fields
            Object.entries(formState).forEach(([key, value]) => {
                if (key !== 'photoBundle' && key !== 'othersPhotos' && key !== 'mainFoto') {
                    formData.append(key, value);
                }
            });

            // Append the main photo file
            if (formState.mainFoto) {
                formData.append('mainFoto', formState.mainFoto);
            }

            // Append photo bundle files
            formState.photoBundle.photos.forEach((file, index) => {
                formData.append(`photoBundle[photos][${index}]`, file);
            });

            // Append other photos
            formState.othersPhotos.forEach((file, index) => {
                formData.append(`othersPhotos[${index}]`, file);
            });

            // Append token
            formData.append('token', token);
            formData.append('productid', productid);

            // Decide the URL based on whether it's add or edit mode
            const url = '/products/add-variant';

            // Send the FormData object
            const response = await fetch(config.host + url, {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) {
                throw new Error(`Error: ${response.statusText}`);
            }

            const responseData = await response.json();
            setSuccess('V pořádku uloženo');
            console.log('API Response:', responseData);
        } catch (err) {
            setError(`Submission failed: ${err.message}`);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="box box-padding-add">
            <div className="my-header mar-10">
                <h1 className="mar-0">{isEdit ? t('editProduct') : t('addProduct')}</h1>
                <div className="box-row">
                    <button className="button-60" onClick={() => navigate('/my-profile')}>
                        <FontAwesomeIcon icon={faArrowRotateBackward}/> {t('back')}
                    </button>
                </div>
            </div>

            <form onSubmit={handleSubmit} className={'home auto mar-10'}>
                <h2 className="mar-10 text-start p-0">{t('mainData')}</h2>
                <BasicInfoVariant formState={formState} handleChange={handleChange}/>

                <div className="form-group text-start">
                    <label htmlFor="description">{t('productDescription')}</label>
                    <CKEditor
                        editor={ClassicEditor}
                        data={formState.description}
                        onChange={(event, editor) => {
                            const data = editor.getData();
                            handleChange({
                                target: {
                                    id: 'description',
                                    value: data
                                }
                            });
                        }}
                        config={{
                            placeholder: t('descriptionPlaceholder')
                        }}
                    />
                </div>

                <h2 className="mar-10 text-start p-0">{t('equipment')}</h2>

                <div className={'flex flex-wrap gap-20  mar-t-20'}>
                    <CheckboxGroup
                        options={waters}
                        formState={formState}
                        handleChange={handleChange}
                        t={t}
                    />
                </div>

                <div className={'flex flex-wrap gap-20  mar-t-20'}>
                    <CheckboxGroup
                        options={services}
                        formState={formState}
                        handleChange={handleChange}
                        t={t}
                    />
                </div>

                <div className={'flex flex-wrap gap-20  mar-t-20'}>
                    <CheckboxGroup
                        options={sports}
                        formState={formState}
                        handleChange={handleChange}
                        t={t}
                    />
                </div>

                <div className={'flex flex-wrap gap-20  mar-t-20'}>
                    <CheckboxGroup
                        options={others}
                        formState={formState}
                        handleChange={handleChange}
                        t={t}
                    />
                </div>

                <div className={'flex flex-wrap gap-20  mar-t-20'}>
                    <CheckboxGroup
                        options={eats}
                        formState={formState}
                        handleChange={handleChange}
                        t={t}
                    />
                </div>

                <div className={'flex flex-wrap gap-20  mar-t-20'}>
                    <div className="form-group switch">
                        <label className="switch-container switchLabel">
                            <input
                                type="checkbox"
                                id="allForUs"
                                checked={formState.allForUs}
                                onChange={handleChange}
                            />
                            <span className="slider round"></span>
                            <span className="switch-label">{t('allForUs')}</span>
                        </label>
                    </div>
                    <div className="form-group switch">
                        <label className="switch-container switchLabel">
                            <input
                                type="checkbox"
                                id="restaurant"
                                checked={formState.restaurant}
                                onChange={handleChange}
                            />
                            <span className="slider round"></span>
                            <span className="switch-label">{t('restaurant')}</span>
                        </label>
                    </div>
                    <div className="form-group switch">
                        <label className="switch-container switchLabel">
                            <input
                                type="checkbox"
                                id="lounge"
                                checked={formState.lounge}
                                onChange={handleChange}
                            />
                            <span className="slider round"></span>
                            <span className="switch-label">{t('lounge')}</span>
                        </label>
                    </div>
                    <div className="form-group switch">
                        <label className="switch-container switchLabel">
                            <input
                                type="checkbox"
                                id="salon"
                                checked={formState.salon}
                                onChange={handleChange}
                            />
                            <span className="slider round"></span>
                            <span className="switch-label">{t('salon')}</span>
                        </label>
                    </div>
                </div>

                <h2 className="mar-10 text-start p-0">{t('nextPhotos')}</h2>
                <div className="form-group">
                    <div {...getRootProps({className: 'dropzone justify-start'})}>
                        <input {...getInputProps()} />
                        {formState.photoBundle.photos.length === 0 && preloadedPhotos.length === 0 && (
                            <p>Přetáhněte sem soubory nebo klikněte pro výběr</p>
                        )}

                        {/* Načtené fotky */}
                        <div className="thumbs-container">
                            {preloadedPhotos.map((photo, index) => (
                                <div key={index} className="thumb">
                                    <img src={photo} alt={`preloaded-${index}`}/> {/* Corrected this line */}
                                    <button
                                        type="button"
                                        onClick={() => removePreloadedPhoto(photo)} // Ensure this matches your remove function
                                        className="remove-button"
                                    >
                                        Odstranit
                                    </button>
                                </div>
                            ))}

                            {/* Fotky přidané uživatelem */}
                            {formState.photoBundle.photos.map((file, index) => (
                                <div key={index} className="thumb">
                                    <img src={URL.createObjectURL(file)} alt="preview"/>
                                    <button
                                        type="button"
                                        onClick={() => removePhoto(file)}
                                        className="remove-button"
                                    >
                                        Odstranit
                                    </button>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <div className={'flex flex-wrap gap-20 mar-t-20'}>
                    <button type="submit" className={'save-button'} disabled={isSubmitting}>
                        {isSubmitting ? t('Submitting') : t('Submit')}
                    </button>
                </div>

                {
                    error && <p style={{color: 'red'}}>{error}</p>
                }
                {
                    success && <p style={{color: 'green'}}>{success}</p>
                }
            </form>
        </div>
    )
};

export default AccommodationForm;
