import React, {useState} from 'react';
import {postRequest} from '../services/apiService';
import login_back from "../assets/images/login_back.webp";
import {useTranslation} from "react-i18next";
import {Link, useNavigate} from 'react-router-dom';
import config from "../Config";
import authService from '../services/authService'; // Import authService

const LoginPage = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [rememberMe, setRememberMe] = useState(false);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');

        try {
            const headers = { 'email': email,'password': password, };  // Použití X-Auth-Token
            const response = await postRequest(config.host + '/auth/login', {}, headers);

            if (response && response.token) {
                authService.login(response.token, rememberMe);
                navigate('/my-profile');
            } else {
                console.error('Login failed: Invalid token');
                setError(t('loginFail'));
            }
        } catch (err) {
            console.error('Login failed:', err);
            setError(t('loginFail'));
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="login-form max-w-500">
            <h1 className={'green'}>{t('login-nadpis')}</h1>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <input
                        type="email"
                        id="email"
                        placeholder="E-mail"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <input
                        type="password"
                        placeholder="Heslo"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group wrap-box">
                    <label className="wrap-box">
                        <input
                            className="width-auto"
                            type="checkbox"
                            checked={rememberMe}
                            onChange={(e) => setRememberMe(e.target.checked)}
                        />
                        {t('savePassword')}
                    </label>
                    <Link to="/forgot-password">{t('forgotPassword')}</Link>
                </div>
                {error && <p className="error-message">{error}</p>} {/* Chybová zpráva */}
                <button
                    type="submit"
                    className="button-60 search-btn width-100 mar-20"
                    disabled={loading}
                >
                    {loading ? t('loading') : t('login')}
                </button>
            </form>
            <div className="login-links">
                {t('notHaveAccount')} <Link to="/register">{t('registerHere')}</Link>
            </div>
        </div>
    );
};

export default LoginPage;
