// src/components/ProductList.js
import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChartArea, faEdit, faEye, faPlus, faTrash} from '@fortawesome/free-solid-svg-icons';
import {useTranslation} from "react-i18next";

const ProductList = ({products, onEdit, onView, onDelete, onStatistics,onAddVariants}) => {
    const {t} = useTranslation();
    return (
        <ul className="product-list">
            {products.map(item => (
                <li key={item.id} className="product-item">
                    <div className="product-image-container" style={{backgroundImage: `url(https://chatynapronajem.cz/backend/www/Soubory/Products/${item.id}/${item.mainFoto})`}}>
                        <div className="product-name">
                            <span>{item.name}</span>
                        </div>
                        <div className="show-info">
                            <span>142 {t('shows')}</span>
                        </div>
                        <div className="actions">
                            <button className={'action-button'} onClick={() => onAddVariants(item.id)}>
                                <FontAwesomeIcon icon={faPlus}/>
                            </button>
                            <button  className={'action-button'} onClick={() => onEdit(item.id)}>
                                <FontAwesomeIcon icon={faEdit}/>
                            </button>
                            <button  className={'action-button'} onClick={() => onView(item.id)}>
                                <FontAwesomeIcon icon={faEye}/>
                            </button>
                            <button  className={'action-button'} onClick={() => onStatistics(item.id)}>
                                <FontAwesomeIcon icon={faChartArea}/>
                            </button>
                            <button  className={'action-button'} onClick={() => onDelete(item.id)}>
                                <FontAwesomeIcon icon={faTrash}/>
                            </button>
                        </div>
                    </div>
                </li>
            ))}
        </ul>
    );
};

export default ProductList;
