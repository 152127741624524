// src/hooks/useProducts.js
import {useEffect, useState} from 'react';
import {deleteProduct, fetchProducts, fetchVariants} from '../services/productsService';
import {useTranslation} from 'react-i18next';

export const useProducts = (token, typ, productid = null) => {
    const {t} = useTranslation();
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        const loadProducts = async () => {
            setLoading(true);
            setError('');
            try {
                let products = [];
                if (typ === 'products') {
                    products = await fetchProducts(token);
                }
                if (typ === 'variants') {
                    products = await fetchVariants(token, productid);
                }
                setProducts(products);
            } catch (err) {
                console.error('API Error:', err);
                setError(t('fetchProductsError'));
            } finally {
                setLoading(false);
            }
        };
        loadProducts();
    }, [t, token]);  // Add token as a dependency

    const handleDelete = async (productId) => {
        try {
            await deleteProduct(productId, token);  // Pass token to deleteProduct
            setProducts(products.filter(product => product.id !== productId));
        } catch (err) {
            console.error('Delete Error:', err);
            setError(t('deleteProductError'));
        }
    };

    return {products, loading, error, handleDelete};
};

