// src/services/productsService.js
import {deleteRequest, postRequest} from '../services/apiService';
import config from '../Config';
import authService from '../services/authService';

export const fetchProducts = async (token) => {
    const headers = { 'X-Auth-Token': token };  // Použití X-Auth-Token
    return await postRequest(config.host + '/products/get-products', {}, headers);
};

export const fetchVariants = async (token,productID) => {
    const headers = { 'X-Auth-Token': token };  // Použití X-Auth-Token
    return await postRequest(config.host + '/products/get-variants', {productID}, headers);
};

export const fetchProduct = async (token,id) => {
    const headers = { 'X-Auth-Token': token };  // Použití X-Auth-Token
    return await postRequest(config.host + '/products/get-product', {id}, headers);
};

export const fetchVariant = async (token,id) => {
    const headers = { 'X-Auth-Token': token };  // Použití X-Auth-Token
    return await postRequest(config.host + '/products/get-variant', {id}, headers);
};

export const searchProducts = async (searchParams) => {
    return await postRequest(config.host + '/products/search-products', {searchParams});
};

export const fetchPhotos = async (token,id) => {
    const headers = { 'X-Auth-Token': token };  // Použití X-Auth-Token
    return await postRequest(config.host + '/products/get-product-photos', {id}, headers);
};

export const fetchVariantPhotos = async (token,id) => {
    const headers = { 'X-Auth-Token': token };  // Použití X-Auth-Token
    return await postRequest(config.host + '/products/get-variants-photos', {id}, headers);
};

export const deleteProduct = async (productId) => {
    const token = authService.isAuthenticated();
    if (!config.dev) {
        await deleteRequest(`/products/get-products/${productId}`, token);
    }
};
