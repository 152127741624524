import React, { useState, useEffect } from 'react';
import heart from "../assets/icons/heart.png";
import '../assets/css/search.css';
import { searchProducts } from "../services/productsService";
import HeaderSection from '../components/Search/SearchForm';

const SearchPage = () => {
    const [products, setProducts] = useState([]);
    const [searchParams, setSearchParams] = useState({}); // Stavy pro vyhledávací parametry

    useEffect(() => {
        const fetchData = async () => {
            if (Object.keys(searchParams).length > 0) {
                try {
                    const response = await searchProducts(searchParams);
                    const data = await response.json();
                    console.log("Fetched products:", data);
                    setProducts(data);
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            }
        };

        fetchData();
    }, [searchParams]);


    return (
        <div className="search-page box-padding-add">
            <HeaderSection onSearch={setSearchParams}/>

            <div className="search-pagination">
                {/* Pagination buttons */}
                <button className="search-pagination-button">«</button>
                <button className="search-pagination-button">1</button>
                <button className="search-pagination-button">2</button>
                <button className="search-pagination-button">3</button>
                <button className="search-pagination-button">»</button>
            </div>
        </div>
    );
};

export default SearchPage;
